<template>
    <div class="login-container-wrapper slide-in-left" :class="{'slide-out-left': closing}" v-show="showLoginScreen"
         :style="cssVars">
        <div class="login-wrapper-inner">
            <div class="close-button" @click="close"><i class="fa fa-times" aria-hidden="true"></i></div>
            <a @click="bannerClickHandler" :href="selectedImage.link" ref="mobileBanner" :target="selectedImage.target"
               class="mobile-banner"><img
                v-if="showLoginScreen && !this.$features.WHITELABEL"
                :src="selectedImage.image"
                :alt="selectedImage.alt"/></a>
            <div id="eh-login-container"></div>
            <login-ad v-if="showLoginScreen" class="login-ad-container"></login-ad>
        </div>
    </div>
</template>

<script>
import LoginAd from "./LoginAd";
import {services} from "../../main";
import {makeBackgroundNotScrollable, makeBackgroundScrollable} from "../../services/Utilities";

export default {
    name: "CustomLoginContainer",
    components: {LoginAd},
    data() {
        return {
            showLoginScreen: false,
            closing: false,
            selectedImage: {}
        }
    },
    computed: {
        images: function () {
            return [
                {
                    alt: "Zoll Karriere",
                    image: `https://company.platform.entwicklerheld.de/link/zoll-karriere-login-screen-quer`,
                    link: "https://company.platform.entwicklerheld.de/link/zoll-karriere-login-screen-mobile-link",
                    target: "_blank",
                    fontColor: "#f6ba19",
                    brandedEHLogo: "https://res.cloudinary.com/dspi5zyaa/image/upload/v1720156896/eh-platform/zoll-karriere/eh-logo-rheinwerk-orange.svg",
                }
            ]
        },
        cssVars: function () {
            return {
                '--login-font-color': `${this.selectedImage.fontColor}`,
                '--login-button-color': `${this.selectedImage.fontColor}`,
                '--close-button-color': this.$features.WHITELABEL ? this.$whitelabelConfig.highlightColor : 'whitesmoke',
            }
        },
        logo: function () {
            return this.selectedImage.brandedEHLogo;
        }
    },
    created() {
        if (this.$features.WHITELABEL) {
            this.selectedImage = {
                image: this.$whitelabelConfig.loginImage,
                link: "",
                fontColor: this.$whitelabelConfig.highlightColor,
                brandedEHLogo: this.$whitelabelConfig.logo,
            }
            return;
        }
        this.selectedImage = this.getRandomImage();
    },
    mounted() {
        // Login Screen Handling
        services.auth.auth.on("show", () => {
            this.closing = false;
            this.showLoginScreen = true;
            const htmlRootElement = document.getElementsByTagName('html')[0];
            setTimeout(() => {
                makeBackgroundNotScrollable(htmlRootElement);
            }, 500);
        })
        services.auth.auth.on("authenticated", () => {
            setTimeout(() => {
                this.close();
            }, 1500);
        })
        services.auth.auth.on("show", () => {
            this.initializeLogo();
            setTimeout(() => {
                this.resetWidthOfMobileBanner();
            }, 500);
        });
    },
    methods: {
        bannerClickHandler() {
            localStorage.setItem("promotion", this.selectedImage.link);
        },
        resetWidthOfMobileBanner() {
            const mobileBanner = this.$refs.mobileBanner;
            if (mobileBanner) {
                mobileBanner.style.width = "100%";
            }
        },
        getRandomImage() {
            return this.images[Math.floor(Math.random() * this.images.length)];
        },
        initializeLogo: function () {
            let auth0LogoElement = document.getElementsByClassName("auth0-lock-header-logo")[0];
            if (!auth0LogoElement) {
                this.initializeLogo();
            }
            auth0LogoElement.src = this.logo;
        },
        close: function () {
            this.closing = true;
            makeBackgroundScrollable();
            setTimeout(() => {
                this.showLoginScreen = false;
                services.auth.auth.hide();
            }, 300);

        },
    }
}
</script>

<style scoped>
.login-container-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: white;
    overflow-y: auto;
}

#eh-login-container {
    z-index: 0;
    position: relative;
    width: 100%;
}

@media (max-width: 481px) {
    #eh-login-container {
        z-index: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .mobile-banner > img {
        width: 100%;
    }

    .mobile-banner {
        display: block;
        position: absolute;
        top: 0;
        z-index: 1;
    }

    .login-wrapper-inner >>> .auth0-lock.auth0-lock .auth0-lock-header {
        padding-bottom: 2rem !important;
    }

    .login-wrapper-inner >>> .auth0-lock.auth0-lock .auth0-lock-header-logo {
        width: 75%;
    }

    .login-wrapper-inner >>> .auth0-lock-header {
        margin-top: 28%;
    }

    .close-button {
        color: whitesmoke !important;
        opacity: 0.6;
    }
}

@media (min-width: 481px) {
    .mobile-banner {
        display: none;
    }

    #eh-login-container {
        display: block;
        width: auto;
    }
}

.close-button {
    position: absolute;
    right: 10px;
    font-size: 3rem;
    z-index: 2;
    color: var(--close-button-color);
}

.close-button:hover {
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .close-button {
        color: var(--login-font-color);
    }
}

.slide-in-left {
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.login-wrapper-inner {
    display: flex;
    align-items: stretch;
    position: relative;
    min-height: 100vh;
    justify-content: center;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-9-21 16:44:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-out-left {
    -webkit-animation: slide-out-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-out-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-10-17 21:6:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
}

@keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
}


@media (max-width: 900px) {
    .login-ad-container {
        display: none;
    }

    .login-wrapper-inner {
        align-items: center;
    }
}
</style>
